<template>
  <div class="main-container">
    <div class="main-header">
      <span class="title-div">{{ this.$route.query.agencyName }}</span>
      <span></span>
    </div>
    <div class="main-bottom">
      <el-form :model="form" ref="form" label-width="55px" :inline="true">
        <div class="title-div">网站负责人</div>
        <div
          style="
            border-bottom: 5px solid rgb(237, 240, 245);
            padding-left: 20px;
          "
        >
          <el-form-item
            prop="owner"
            label="姓名"
            :rules="[
              { required: true, message: '网站负责人姓名', trigger: 'blur' },
            ]"
          >
            <el-input v-model="form.owner"></el-input>
          </el-form-item>
          <el-form-item
            prop="ownerMobile"
            label="电话"
            :rules="[
              { required: true, message: '网站负责人电话', trigger: 'blur' },
            ]"
          >
            <el-input v-model="form.ownerMobile"></el-input>
          </el-form-item>
        </div>
        <div class="title-div">
          得分预警
          <el-switch
            v-model="form.warned"
            style="margin-left: 20px"
          ></el-switch>
        </div>
        <div style="padding-left: 20px">
          <div style="margin-bottom: 12px; color: #409eff">预警条件：</div>
          <el-form-item
            prop="warnedCondition"
            label="得分 <"
            :rules="[
              {
                required: true,
                message: '预警条件：得分小于时预警',
                trigger: 'blur',
              },
            ]"
          >
            <el-input v-model="form.warnedCondition"></el-input>
          </el-form-item>
        </div>

        <div style="padding-left: 20px">
          <div style="margin-bottom: 12px; color: #409eff">预警联系人：</div>
          <div v-for="(item, index) in form.warnedInfoList" :key="index">
            <el-form-item label="姓名">
              <el-input v-model="item.warnedOwner"></el-input>
            </el-form-item>
            <el-form-item prop="owner" label="电话">
              <el-input v-model="item.warnedOwnerMobile"></el-input>
            </el-form-item>
            <el-button
              @click.prevent="removeDomain(item)"
              v-if="form.warnedInfoList.length > 1"
              >删除</el-button
            >
            <el-button
              @click="addDomain"
              v-if="index + 1 === form.warnedInfoList.length"
              >新增</el-button
            >
          </div>
        </div>
        <el-form-item style="margin: 10px 0 0 25%; padding-bottom: 20px">
          <el-button type="primary" @click="submitForm('form')">提交</el-button>
          <el-button @click="resetForm('form')">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      form: {
        agencyName: '', //机构名称
        agencyId: null, //机构ID
        owner: '', //网站负责人姓名
        ownerMobile: '', //网站负责人电话
        warned: true, //得分预警
        warnedCondition: '', //预警条件：得分小于时预警
        remark: null, //备注
        warnedInfoList: [
          {
            warnedOwner: '', //预警联系人
            warnedOwnerMobile: '', //预警联系人手机号
          },
          {
            warnedOwner: '', //预警联系人
            warnedOwnerMobile: '', //预警联系人手机号
          },
        ],
      },
    };
  },
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          if (this.form.id) {
            let res = await this.$api.operationOrder.updateSiteconfig(
              this.form
            );
            if (res.code == 0) {
              this.$message.success('更新成功');
              this.$router.go(-1);
            }
          } else {
            let res = await this.$api.operationOrder.createSiteconfig(
              this.form
            );
            if (res.code == 0) {
              this.$message.success('创建成功');
              this.$router.go(-1);
            }
          }
        } else {
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    removeDomain(item) {
      var index = this.form.warnedInfoList.indexOf(item);
      if (index !== -1 && this.form.warnedInfoList.length > 1) {
        this.form.warnedInfoList.splice(index, 1);
      }
    },
    addDomain() {
      this.form.warnedInfoList.push({
        warnedOwner: '', //预警联系人
        warnedOwnerMobile: '', //预警联系人手机号
      });
    },
    async getSiteconfigList() {
      let obj = {
        agencyId: this.$route.query.agencyId,
        pageNum: 1,
        pageSize: 10,
      };
      let res = await this.$api.operationOrder.getSiteconfigList(obj);
      if (res.code === 0 && res.data.list.length !== 0) {
        this.form = res.data.list[0];
      }
    },
  },
  mounted() {
    this.form.agencyName = this.$route.query.agencyName;
    this.form.agencyId = this.$route.query.agencyId;
    this.getSiteconfigList();
  },
};
</script>
<style lang="scss" scoped>
.main-header {
  height: 50px;
  width: 100%;
  line-height: 50px;
  background-color: #fff;
}
.title-div {
  font-weight: 800;
  font-size: 16px;
  margin-left: 20px;
  margin-bottom: 8px;
}
.main-bottom {
  height: calc(100%-50px);
  width: 100%;
  margin-top: 5px;
  background-color: #fff;
}
</style>