var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"main-container"},[_c('div',{staticClass:"main-header"},[_c('span',{staticClass:"title-div"},[_vm._v(_vm._s(this.$route.query.agencyName))]),_c('span')]),_c('div',{staticClass:"main-bottom"},[_c('el-form',{ref:"form",attrs:{"model":_vm.form,"label-width":"55px","inline":true}},[_c('div',{staticClass:"title-div"},[_vm._v("网站负责人")]),_c('div',{staticStyle:{"border-bottom":"5px solid rgb(237, 240, 245)","padding-left":"20px"}},[_c('el-form-item',{attrs:{"prop":"owner","label":"姓名","rules":[
            { required: true, message: '网站负责人姓名', trigger: 'blur' } ]}},[_c('el-input',{model:{value:(_vm.form.owner),callback:function ($$v) {_vm.$set(_vm.form, "owner", $$v)},expression:"form.owner"}})],1),_c('el-form-item',{attrs:{"prop":"ownerMobile","label":"电话","rules":[
            { required: true, message: '网站负责人电话', trigger: 'blur' } ]}},[_c('el-input',{model:{value:(_vm.form.ownerMobile),callback:function ($$v) {_vm.$set(_vm.form, "ownerMobile", $$v)},expression:"form.ownerMobile"}})],1)],1),_c('div',{staticClass:"title-div"},[_vm._v(" 得分预警 "),_c('el-switch',{staticStyle:{"margin-left":"20px"},model:{value:(_vm.form.warned),callback:function ($$v) {_vm.$set(_vm.form, "warned", $$v)},expression:"form.warned"}})],1),_c('div',{staticStyle:{"padding-left":"20px"}},[_c('div',{staticStyle:{"margin-bottom":"12px","color":"#409eff"}},[_vm._v("预警条件：")]),_c('el-form-item',{attrs:{"prop":"warnedCondition","label":"得分 <","rules":[
            {
              required: true,
              message: '预警条件：得分小于时预警',
              trigger: 'blur',
            } ]}},[_c('el-input',{model:{value:(_vm.form.warnedCondition),callback:function ($$v) {_vm.$set(_vm.form, "warnedCondition", $$v)},expression:"form.warnedCondition"}})],1)],1),_c('div',{staticStyle:{"padding-left":"20px"}},[_c('div',{staticStyle:{"margin-bottom":"12px","color":"#409eff"}},[_vm._v("预警联系人：")]),_vm._l((_vm.form.warnedInfoList),function(item,index){return _c('div',{key:index},[_c('el-form-item',{attrs:{"label":"姓名"}},[_c('el-input',{model:{value:(item.warnedOwner),callback:function ($$v) {_vm.$set(item, "warnedOwner", $$v)},expression:"item.warnedOwner"}})],1),_c('el-form-item',{attrs:{"prop":"owner","label":"电话"}},[_c('el-input',{model:{value:(item.warnedOwnerMobile),callback:function ($$v) {_vm.$set(item, "warnedOwnerMobile", $$v)},expression:"item.warnedOwnerMobile"}})],1),(_vm.form.warnedInfoList.length > 1)?_c('el-button',{on:{"click":function($event){$event.preventDefault();return _vm.removeDomain(item)}}},[_vm._v("删除")]):_vm._e(),(index + 1 === _vm.form.warnedInfoList.length)?_c('el-button',{on:{"click":_vm.addDomain}},[_vm._v("新增")]):_vm._e()],1)})],2),_c('el-form-item',{staticStyle:{"margin":"10px 0 0 25%","padding-bottom":"20px"}},[_c('el-button',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.submitForm('form')}}},[_vm._v("提交")]),_c('el-button',{on:{"click":function($event){return _vm.resetForm('form')}}},[_vm._v("重置")])],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }